import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () => import("@/modules/campaigns/views/TheCampaigns.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta" },
  },
  {
    path: "/campaign/:campaignId",
    name: "CampaignById",
    component: () => import("@/modules/campaigns/views/TheCampaignById.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
  },
];
