import type { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import AuthService from "./AuthService";

export default class AuthGuard {
  static withoutToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (AuthService.isAuthenticated()) {
      return next({
        name: "Dashboard",
      });
    }

    return next();
  }

  static withToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (AuthService.isAuthenticated()) {
      return next();
    }

    return next({
      name: "Login",
    });
  }

  /*static async withPermissionAccess(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {   
    //TOOD With permission
  }*/
}
