import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/administrators",
    name: "Administrators",
    component: () => import("@/modules/administrators/views/TheAdministrators.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "administrators.meta" },
  },
];
