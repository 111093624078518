import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import AccountRoutes from "@/modules/account/router";
import DashboardRoutes from "@/modules/dashboard/router";
import CreatorsRoutes from "@/modules/creators/router";
import CompaniesRoutes from "@/modules/companies/router";
import AdministratorsRoutes from "@/modules/administrators/router";
import ErrorRoutes from "@/modules/error/router";
import CampaignsRoutes from "@/modules/campaigns/router";

const baseRoutes: RouteRecordRaw[] = [];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AccountRoutes,
  DashboardRoutes,
  CreatorsRoutes,
  CompaniesRoutes,
  AdministratorsRoutes,
  ErrorRoutes,
  CampaignsRoutes
);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
