import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/creators",
    name: "Creators",
    component: () => import("@/modules/creators/views/TheCreators.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "creators.meta" },
  },
];
