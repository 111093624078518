import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "dashboard.meta" },
  },
];
